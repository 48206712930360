export const MenuItems = [
  {
    title: "Start",
    url: "/",
    cName: "nav-links",
  },

  {
    title: "Oferta",
    url: "/offer",
    cName: "nav-links",
  },
  {
    title: "Galeria",
    url: "/gallery",
    cName: "nav-links",
  },
  {
    title: "O nas",
    url: "/about",
    cName: "nav-links",
  },
  {
    title: "Kontakt",
    url: "/contact",
    cName: "nav-links",
  },
];
