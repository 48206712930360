import React, { useState, useEffect } from "react";
import "./PhotoGalleryStyles.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CloseIcon from "@material-ui/icons/Close";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Img1 from "../assets/gallery/gallery_1.webp";
import Img2 from "../assets/gallery/gallery_2.webp";
import Img3 from "../assets/gallery/gallery_3.webp";
import Img4 from "../assets/gallery/gallery_4.webp";
import Img5 from "../assets/gallery/gallery_5.webp";
import Img6 from "../assets/gallery/gallery_6.webp";

const PhotoGallery = () => {
  const data = [
    { id: 1, imgSrc: Img1 },
    { id: 2, imgSrc: Img2 },
    { id: 3, imgSrc: Img3 },
    { id: 4, imgSrc: Img4 },
    { id: 5, imgSrc: Img5 },
    { id: 6, imgSrc: Img6 },
  ];
  const navigate = useNavigate();
  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 37) {
        // Left arrow key
        handlePrev();
      } else if (event.keyCode === 39) {
        // Right arrow key
        handleNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentIndex]);

  const getImg = (imgSrc, index) => {
    setTempImgSrc(imgSrc);
    setCurrentIndex(index);
    setModel(true);
  };

  const handlePrev = () => {
    const newIndex = (currentIndex - 1 + data.length) % data.length;
    setCurrentIndex(newIndex);
    setTempImgSrc(data[newIndex].imgSrc);
  };

  const handleNext = () => {
    const newIndex = (currentIndex + 1) % data.length;
    setCurrentIndex(newIndex);
    setTempImgSrc(data[newIndex].imgSrc);
  };

  const closeFullScreen = () => {
    setModel(false);
  };

  return (
    <div className="gallery-container">
      <h1 className="full-width">Galeria</h1>

      <div className={model ? "model open" : "model"}>
        <div className="fullscreen-overlay">
          <FaArrowLeft className="arrow arrow_left" onClick={handlePrev} />
          <FaArrowRight className="arrow arrow_right" onClick={handleNext} />
          <CloseIcon className="close" onClick={closeFullScreen} />
          <img className="fullscreen-image" src={tempimgSrc} alt="Fullscreen" />
        </div>
      </div>
      <div className="photo-gallery">
        {/* Karuzela */}
        <div className="carousel">
          <Carousel
            showThumbs={false}
            selectedItem={currentIndex}
            renderArrowPrev={(onClickHandler, hasPrev, label) => (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="control-arrow control-prev"
                disabled={!hasPrev}
              >
                <FaArrowLeft className="arrow-left" />
              </button>
            )}
            renderArrowNext={(onClickHandler, hasNext, label) => (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="control-arrow control-next"
                disabled={!hasNext}
              >
                <FaArrowRight className="arrow-right" />
              </button>
            )}
          >
            {data.map((item, index) => (
              <div
                className="pics"
                key={index}
                onClick={() => getImg(item.imgSrc, index)}
              >
                <img
                  src={item.imgSrc}
                  style={{ width: "100%" }}
                  alt={`Image ${index}`}
                />
              </div>
            ))}
          </Carousel>
        </div>
        {/* Miniatury */}
        <div className="thumbnails">
          {data.map((item, index) => (
            <div
              className={`thumbnail ${currentIndex === index ? "active" : ""}`}
              key={index}
              onClick={() => getImg(item.imgSrc, index)}
            >
              <img
                src={item.imgSrc}
                style={{ width: "100%" }}
                alt={`Thumbnail ${index}`}
              />
            </div>
          ))}
        </div>
      </div>

      <a onClick={() => navigate("/Gallery")}>
        <button className="offer-button_main">Zobacz więcej</button>
      </a>
    </div>
  );
};

export default PhotoGallery;
