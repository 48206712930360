import React, { useState } from "react";
import "./ContactFormStyles.css";

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm(formData);
    if (Object.keys(errors).length === 0) {
      // Wysyłanie danych formularza
      console.log("Formularz jest poprawny, można wysłać dane:", formData);
    } else {
      setErrors(errors);
    }
  };

  const validateForm = (data) => {
    const errors = {};
    if (!data.name.trim()) {
      errors.name = "Proszę podać imię";
    }
    if (!data.email.trim()) {
      errors.email = "Proszę podać adres e-mail";
    } else if (!isValidEmail(data.email)) {
      errors.email = "Proszę podać poprawny adres e-mail";
    }
    if (!data.subject.trim()) {
      errors.subject = "Proszę podać temat";
    }
    if (!data.message.trim()) {
      errors.message = "Proszę wpisać wiadomość";
    }
    return errors;
  };

  const isValidEmail = (email) => {
    // Prosta walidacja adresu e-mail
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  return (
    <div className="form-container">
      <h1 className="full-width">Kontakt</h1>
      <div className="contact">
        <div className="contact_form">
          <h4 className="contact-title">Formularz kontaktowy</h4>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Imię"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <span className="error">{errors.name}</span>}
            <input
              type="email"
              name="email"
              placeholder="E-mail"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <span className="error">{errors.email}</span>}
            <input
              type="text"
              name="subject"
              placeholder="Temat"
              value={formData.subject}
              onChange={handleChange}
            />
            {errors.subject && <span className="error">{errors.subject}</span>}
            <input
              type="text"
              name="phone"
              placeholder="Telefon"
              value={formData.phone}
              onChange={handleChange}
            />
            <textarea
              name="message"
              placeholder="Wiadomość"
              rows="4"
              value={formData.message}
              onChange={handleChange}
            />
            {errors.message && <span className="error">{errors.message}</span>}
            <button type="submit" className="offer-button_main f">
              Wyślij
            </button>
          </form>
        </div>
        <div className="contact_info">
          <div className="contact_info-down">
            <div className="contact_info-down-left">
              <h4 className="contact-title down">Informacje ogólne</h4>
              <p className="contact_info-text">
                info@sk8yard.com
                <br /> (+48) 785-066-111
              </p>
              <h4 className="m contact-title down more">Rekrutacja</h4>
              <p className="m contact_info-text more">rekrutacja@sk8yard.com</p>
            </div>
            <div className="contact_info-down-right">
              <h4 className="contact-title down ">Projekty i konsultacje</h4>
              <p className="contact_info-text">
                projekty@sk8yard.com
                <br /> (+48) 883-044-014
              </p>
              <h4 className="m contact-title down more">Księgowość</h4>
              <p className="m contact_info-text more">ksiegowosc@sk8yard.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
