import "./OurOfferStyles.css";
import { useNavigate } from "react-router-dom";
import Offert1 from "../assets/offer_1.webp";
import Offert2 from "../assets/offer_2.webp";
import Offert3 from "../assets/offer_3.webp";
import Offert4 from "../assets/offer_4.webp";
import Offert5 from "../assets/offer_5.webp";
import Offert6 from "../assets/offer_6.webp";

function OurOffer() {
  const navigate = useNavigate();

  return (
    <div className="offer">
      <h1 className="full-width">oferta</h1>
      <div className="offercard">
        <div className="o-card o-card-image">
          <h4 className="o-card-title">Planowanie</h4>
          <img src={Offert1} alt="Konsultacje" />
          <div className="o-card-text">
            <ul>
              <li>Spotkania z włodarzami</li>
              <li>Wizje lokalne</li>
              <li>Konsultacje z użytkownikami</li>
            </ul>
          </div>
          <a onClick={() => navigate("/Offer")} className="offer-button">
            Czytaj więcej
          </a>
        </div>

        <div className="o-card o-card-image">
          <h4 className="o-card-title">Projektowanie</h4>
          <img src={Offert2} alt="Projektowanie" />
          <div className="o-card-text">
            <ul>
              <li>Koncepcje indywidualne</li>
              <li>Rysunki i opisy techniczne</li>
              <li>Dokumentacje projektowe</li>
            </ul>
          </div>
          <a onClick={() => navigate("/Offer")} className="offer-button">
            Czytaj więcej
          </a>
        </div>

        <div className="o-card o-card-image">
          <h4 className="o-card-title">Budowa </h4>
          <img src={Offert3} alt="Budowa skateparków" />
          <div className="o-card-text">
            <ul>
              <li>Urządzenia betonowe</li>
              <li>Sklejkowo-kompozytowe</li>
              <li>Na konstrukcji stalowej</li>
            </ul>
          </div>
          <a onClick={() => navigate("/Offer")} className="offer-button">
            Czytaj więcej
          </a>
        </div>
        <div className="o-card o-card-image">
          <h4 className="o-card-title">Pumptracki modułowe</h4>
          <img src={Offert4} alt="Pumptracki modułowe" />
          <div className="o-card-text">
            <ul>
              <li>Szybki montaż nawet w 1 dzień</li>
              <li>Bezpieczna nawierzchnia antypoślizgowa</li>
              <li>Dowolna kolorystyka z palety RAL</li>
            </ul>
          </div>
          <a onClick={() => navigate("/Offer")} className="offer-button">
            Czytaj więcej
          </a>
        </div>
        <div className="o-card o-card-image">
          <h4 className="o-card-title">Urządenia mobilne</h4>
          <img src={Offert5} alt="Urządenia mobilne" />
          <div className="o-card-text">
            <ul>
              <li>Sprzedaż mobilnych urządzeń</li>
              <li>Budowa na potrzeby własne i komercyjne</li>
              <li>Wynajem i pomoc w organizacji eventów</li>
            </ul>
          </div>
          <a onClick={() => navigate("/Offer")} className="offer-button">
            Czytaj więcej
          </a>
        </div>
        <div className="o-card o-card-image">
          <h4 className="o-card-title">Serwis i modernizacje</h4>
          <img src={Offert6} alt="Serwis i modernizacje" />
          <div className="o-card-text">
            <ul>
              <li>Przeglądy gwarancyjne i pogwarancyjne</li>
              <li>Naprawy i konserwacje </li>
              <li>Rozbudowa i modernizacje</li>
            </ul>
          </div>
          <a onClick={() => navigate("/Offer")} className="offer-button">
            Czytaj więcej
          </a>
        </div>
      </div>
      <a onClick={() => navigate("/Offer")}>
        <button className="offer-button_main">Czytaj więcej</button>
      </a>
    </div>
  );
}
export default OurOffer;
