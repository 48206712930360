import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Importuj styl AOS
import logo_title_black from "../assets/logo_title_black.png";
import "./AboutUsStyles.css"; // Importuj style dla AboutUs

import { useNavigate } from "react-router-dom";

function AboutUs(props) {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 500, // czas trwania animacji w milisekundach
      offset: 5, // odstęp od dolnej krawędzi okna przeglądarki, aby rozpocząć animację
      easing: "ease-in-out", // funkcja przejścia animacji
    });
  }, []);
  return (
    <>
      <div className={props.about_cName}>
        <img alt="HeroImg" className="back_img" src={props.aboutImg} />
        <div className="about-text">
          <div className="about_title_black" data-aos="fade-up">
            <img src={logo_title_black} alt="logo" />
          </div>
          <h2 data-aos="fade-up">{props.about_subtitle}</h2>
          <p data-aos="fade-up"> {props.about_text}</p>
          <div
            data-aos="fade-up"
            onClick={() => navigate("/About")}
            className={props.btnClass}
          >
            Czytaj więcej
          </div>
        </div>
      </div>
    </>
  );
}
export default AboutUs;
